import Script from "next/script";
import React from "react";

export const pageview = (url) => {
    const pageEvent = {
        event: "pageview",
        page: url,
    }

    window && window.dataLayer && window.dataLayer.push(pageEvent);
    return pageEvent;
}

export default function GoogleTagManager({ trackingId }) {
    const isProduction = process.env.NODE_ENV === "production";
    if (!isProduction) return null;

    return (
        <>
            <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${trackingId}');`,
                }}
            />

            {/* Google Tag Manager */}
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${trackingId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                }}
            />
            {/* End Google Tag Manager (noscript) */}
        </>
    );
}
