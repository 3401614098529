import axios from "axios";

const useApi = (props) => {
    const api_version = () => {
        let _version = props?.version ?? props ?? 1;

        switch (_version) {
            case 1:
                return "/v1";
            case 2:
                return "/v2";
            case 3:
                return "/v3";
            default:
                throw new Error(`Version not supported. (version: ${props?.version || props})`);
        }
    };

    // eslint-disable-next-line no-undef
    const environment = process?.env?.NODE_ENV ?? "";
    const hostname = (typeof window !== "undefined") ? window?.location?.hostname ?? "" : "";

    const host = () => {
        // Localhost
        if (
            hostname === "localhost" ||
            environment === "development"
        ) {
            return `http://127.0.0.1:3098/api${api_version()}`;
        }

        // Staging
        if (
            hostname === "synega-crm.netlify.app" ||
            hostname === "synega-user.netlify.app" ||
            hostname === "synega-sales.netlify.app" ||
            hostname === "synega-signup.netlify.app"
        ) {
            return `https://synega-test.herokuapp.com/api${api_version()}`;
        }

        // Production
        if (
            hostname === "crm.synega.com" ||
            hostname === "user.synega.com" ||
            hostname === "sales.synega.com" ||
            hostname === "signup.synega.com" ||
            environment === "production"
        ) {
            return `https://synega-frontend-prod.herokuapp.com/api${api_version()}`
            // return `https://www.synega.com/api${api_version()}`;
        }

        throw new Error("Invalid location hostname");
    };

    const get = async (url, data, options = {}) => {
        let formData = convertToFormData(data);

        let headers = {};
        if (options?.authenticate) headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

        return axios({
            method: "GET",
            url: host() + safeUrl(url),
            params: formData ?? data,
            ...options,
            headers,
        });
    }

    const post = async (url, data, options = {}) => {
        let formData = convertToFormData(data);

        let headers = {};
        if (options?.authenticate) headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

        return axios({
            method: "POST",
            url: host() + safeUrl(url),
            data: formData ?? data,
            ...options,
            headers,
        });
    }

    const patch = async (url, data, options = {}) => {
        let formData = convertToFormData(data);

        let headers = {};
        if (options?.authenticate) headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

        return axios({
            method: "PATCH",
            url: host() + safeUrl(url),
            data: formData ?? data,
            ...options,
            headers,
        });
    }

    const _delete = async (url, data, options = {}) => {
        let formData = convertToFormData(data);

        let headers = {};
        if (options?.authenticate) headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

        return axios({
            method: "DELETE",
            url: host() + safeUrl(url),
            data: formData ?? data,
            ...options,
            headers,
        });
    }

    const safeUrl = (url) => {
        if (url[0] !== "/") {
            url = "/" + url;
        }
        return url;
    }

    const convertToFormData = (data) => {
        let formData = null;

        Object.keys(data ?? {}).forEach(key => {
            if (typeof data[key] === "object") {
                let isFile = false;
                let fileData = [];

                if (data[key] instanceof Array) {
                    data[key].forEach((item, index) => {
                        if (item instanceof File) {
                            isFile = true;
                            if (formData === null) {
                                formData = new FormData();
                            }

                            formData.append(index, item);
                            fileData.push({
                                name: item.name,
                                size: item.size,
                                extension: item.name.split(".").pop(),
                                last_modified: item.lastModified,
                                content_type: item.type,
                            })
                        }
                    });
                }

                if (isFile) {
                    data[key] = fileData;
                }
            }
        });

        if (formData !== null) {
            Object.keys(data ?? {}).forEach(key => {
                if (typeof data[key] === "object") {
                    const json = JSON.stringify(data[key]);
                    const blob = new Blob([json], {
                        type: "application/json",
                    });
                    formData.append(key, blob);
                } else {
                    formData.append(key, data[key]);
                }
            });
        }

        return formData;
    }

    return { host, get, post, patch, delete: _delete };
};

export default useApi;
