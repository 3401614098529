import axios from "axios";
import useApi from "@/res/hooks/SynegaApi";

export const BrregUrl = (keyword) => {
    if ((keyword?.length ?? 0) === 0) return "";

    let reg = /\D+/g;
    let words = keyword.match(reg);
    let isOrgNumber = words == null;

    const url = new URL("https://data.brreg.no/enhetsregisteret/api/enheter");

    if (isOrgNumber) {
        reg = /\w+/g;
        words = keyword.match(reg);
        url.searchParams.append("organisasjonsnummer", words.join(""));
    } else {
        url.searchParams.append("navn", keyword);
    }

    return url.toString();
};

export const BrregSearch = async (url) => {
    return await axios({
        method: "GET",
        url: url,
    }).then(res => {
        return res?.data?._embedded?.enheter;
    }).then(res => {
        return (res ?? []).map(item => {
            return {
                id: item?.organisasjonsnummer,
                name: item?.navn,
                type: item?.organisasjonsform?.kode,
                address: {
                    country_code: item?.forretningsadresse?.landkode,
                    postal_code: item?.forretningsadresse?.postnummer,
                    city: item?.forretningsadresse?.poststed,
                    street: item?.forretningsadresse?.adresse?.[0],
                },
            }
        })
    }).catch(error => {
        Toast.error("Brønnøysundregistrene", "Noe gikk gale under søket");
        Sentry.captureException(error);
    })
}

export const BrregSignatories = async (orgNumber) => {
    const api = useApi(3)
    return api.get(`/brreg/${orgNumber}/signatories`).then(res => {
        return res?.data;
    });
}
