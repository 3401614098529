import React from "react";
import DefaultHead from "@/components/defaults/head";
import { GenericWrapper } from "@/res/context/generic";
import ClientNav from "@/components/client/nav";
import ClientFooter from "@/components/client/footer";
import GoogleTagManager from "@/helpers/google/tag-manager";

const DefaultComponentWrapper = ({ Component, pageProps }) => {
    const GTM_TRACKING_ID = "GTM-NR4C2N4";

    // useEffect(() => {
    //     if (!isProduction) return;
    //
    //     const handleRouteChange = (url) => {
    //         gtm.pageview(url);
    //     }
    //
    //     router.events.on("routeChangeComplete", handleRouteChange);
    //     return () => {
    //         router.events.off("routeChangeComplete", handleRouteChange);
    //     };
    // }, [router.events])

    return (
        <div id="__default">
            <GoogleTagManager trackingId={GTM_TRACKING_ID}/>

            {/*<I18nProvider i18n={i18n}>*/}
            <DefaultHead/>
            <GenericWrapper storageKey="client">
                <ClientNav {...pageProps}/>
                <Component {...pageProps}/>
                <ClientFooter/>
            </GenericWrapper>
            {/*</I18nProvider>*/}
        </div>
    );
};

export default DefaultComponentWrapper;
